import $ from 'jquery';
//import jQuery from 'jquery';
//import 'bootstrap/js/dist/util';
//import 'bootstrap/js/dist/dropdown';
import { Tooltip, Toast, Popover } from 'bootstrap';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	console.log('document ready');

	// Navigation levels

	$('.navbar .dropdown').hover(function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown(1);
	}, function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp(1);
	});

	$('.navbar .dropdown > a').click(function(){
		location.href = this.href;
	});

	$('.page-template-template-academy .academy-form-wrap').hide();
	

	$( ".page-template-template-academy .academy-radio-wrap label" ).click(function() {
		$('.page-template-template-academy .academy-form-wrap').show(200);
	});

	// Pop-up: appear

	$('.popup-overlay').delay(2000).queue(function(nxt) {
	    $(this).show().addClass('popup-active');
	    nxt();
	});

	// Hide pop-up: close button

	$( ".popup-overlay .close-button" ).click(function() {
		$( ".popup-overlay" ).fadeOut(300).removeClass('popup-active');
	});

	// Hide pop-up: click outer

	$('.popup-overlay').on('click', function(e) {
	if (e.target !== this)
	  return;

		$(this).fadeOut(300).removeClass('popup-active');
	});

	// Academy

	document.addEventListener( 'wpcf7mailsent', function( event ) {
		var radiobutton = $('input[name=subject]:checked', '.wpcf7-form').val();

		if(radiobutton == 'What are IP rights and why are they so important?'){

			window.open('../wp-content/uploads/2022/09/1.-What-are-IP-rights-and-why-are-they-so-important.pdf', '_blank');
			return false;

		}else if( radiobutton == 'Trademarks in a nutshell'){

			window.open('../wp-content/uploads/2022/09/2.-Trademarks-in-a-nutshell.pdf', '_blank');
			return false;

		}else{
			// niets doen
		}

	}, false );


});